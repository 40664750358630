import React from 'react';
import { useMediaPredicate } from 'react-media-hook';
import styled from 'styled-components';
import MarkdownPreview from '@uiw/react-markdown-preview';

import colors from 'assets/global/colors';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  BodyTitle,
  BigQuoteText,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;

  @media (min-width: 875px) {
    flex-direction: row;
  }
`;

const IsItWorthIt = ({ content }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  return (
    <TransformationWhiteWrapper id="isItWorthIt">
      <CustomTransformationBlock>
        {biggerThan875 && (
          <TransformationBlockDiv>
            <BigQuoteText style={{ maxWidth: 500 }}>
              {content.highlightRed}
            </BigQuoteText>
          </TransformationBlockDiv>
        )}

        <TransformationBlockDiv>
          <span className="background-number">6</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>
          <MarkdownPreview source={content.content1} />

          <BodyTitle>{content.highlight}</BodyTitle>

          <MarkdownPreview source={content.content2} />
        </TransformationBlockDiv>

        {!biggerThan875 && (
          <TransformationBlockDiv>
            <BodyTitle
              style={{
                color: colors.red400,
                fontSize: biggerThan875 ? 36 : 24,
                marginBottom: 0
              }}
            >
              {content.highlightRed}
            </BodyTitle>
          </TransformationBlockDiv>
        )}
      </CustomTransformationBlock>
    </TransformationWhiteWrapper>
  );
};

export default IsItWorthIt;
