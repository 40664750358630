import React from 'react';
import styled from 'styled-components';

import Partners from 'components/Partners';

import fonts from 'assets/global/fonts';
import colors from 'assets/global/colors';

import { TransformationBlock, Text } from './styled';

const CustomTransformationBlock = styled(TransformationBlock)`
  @media (max-width: 874px) {
    margin-bottom: 60px;
  }
`;

const CustomTitle = styled.h4`
  font-family: ${fonts.secondary};
  font-weight: bold;
  font-size: 24px;
  color: ${colors.gray300};

  @media (max-width: 874px) {
    margin-top: 0;
  }
`;

const OurPartners = ({ content }) => {
  return (
    <CustomTransformationBlock>
      <CustomTitle>{content.title}</CustomTitle>

      <Partners page={{}} wrapperStyle={{ padding: 0 }} />
    </CustomTransformationBlock>
  );
};

export default OurPartners;
