import React from 'react';
import { lighten } from 'polished';
import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import {
  TransformationWrapper,
  TransformationBlock,
  SubHeadTitle
} from './styled';

const CustomTransformationWrapper = styled(TransformationWrapper)`
  background-color: ${colors.gray50};
  min-height: 0;
  padding: 0 20px;
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  max-width: 812px;
  text-align: center;
  padding: 70px 0;

  @media (max-width: 874px) {
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

const SubTitle = styled.h2`
  margin: 20px auto 50px auto;
  margin-top: 10px;

  font-family: ${fonts.secondary};
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;

  color: ${colors.gray300};

  @media (max-width: 768px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

const StartButton = styled.a`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  width: 259px;
  height: 59px;

  color: ${colors.white} !important;
  background: ${colors.red300};
  border: none;
  cursor: pointer;

  text-decoration: none;
  text-transform: uppercase;
  font-family: ${fonts.primary};
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
  transition: all ease-in-out 0.2s;

  &:hover {
    color: ${colors.white};
    background-color: ${lighten(0.2, colors.red300)};
  }
`;

const ReinventYourBusiness = ({ content }) => {
  return (
    <CustomTransformationWrapper>
      <CustomTransformationBlock>
        <SubHeadTitle
          hasBottomShadow
          style={{
            marginTop: 0,
            marginBottom: 0,
            textAlign: 'center'
          }}
        >
          {content.title}
        </SubHeadTitle>

        <SubTitle>{content.subtitle}</SubTitle>

        <StartButton
          target="_blank"
          href={content.actionUrl}
          title={content.cta}
          callToAction={false}
          variant="light-outline"
          style={{ fontWeight: 400 }}
        >
          {content.cta}
        </StartButton>
      </CustomTransformationBlock>
    </CustomTransformationWrapper>
  );
};

export default ReinventYourBusiness;
