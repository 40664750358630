import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import { useMediaPredicate } from 'react-media-hook';

import ChevronDown from 'assets/images/richcontent/white-chevron.svg';

import MarkdownPreview from '@uiw/react-markdown-preview';

import {
  TransformationWhiteWrapper,
  DesktopListDivider,
  TransformationBlockDiv,
  TitleTextWrapper,
  TransformationBlock,
  LineAboveText,
  SubHeadTitle
} from './styled';

const CasesWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 874px) {
    flex-direction: column;
  }
`;

const SectionWrapper = styled.div`
  flex-basis: 50%;
`;

const CaseSelectorWrapper = styled.div`
  padding: 25px 50px;
  display: flex;
  justify-content: flex-end;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  margin-left: -100px;
  margin-right: 140px;
  transition: all 0.2s ease-out;
  filter: grayscale(1);
  margin-bottom: 8px;

  &:hover {
    margin-right: 120px;
  }

  &.active,
  &:active {
    filter: grayscale(0);
    margin-right: 60px;
  }

  @media (max-width: 874px) {
    margin-left: -20px;
    width: calc(100% + 19px);
    padding: 20px 40px;
  }
`;

const CaseTitle = styled.span`
  display: flex;
  align-items: center;

  font-family: ${fonts.primary};
  font-size: 36px;
  color: white;
  z-index: 1;

  @media (max-width: 874px) {
    font-size: 26px;
  }

  img {
    margin-left: 10px;
    transform: ${({ isActive }) =>
      isActive ? 'rotate(0deg)' : 'rotate(180deg)'};
    transition: transform 0.3s ease-out;
  }
`;

const CaseContentWrapper = styled.div`
  transition: max-height 0.4s ease-in;
  overflow: hidden;
`;

const CaseSelector = ({
  title,
  content,
  image,
  onClick,
  isActive: isActiveProp
}) => {
  const smallerThan875 = useMediaPredicate('(max-width: 874px)');

  const [isActive, setIsActive] = useState(isActiveProp);

  const [maxHeight, setMaxHeight] = useState(
    smallerThan875 && !isActive ? 0 : null
  );

  useEffect(() => {
    if (smallerThan875) return;
    setIsActive(isActiveProp);
  }, [isActiveProp, smallerThan875]);

  function handleClick(event) {
    handleMaxHeight(event);

    if (smallerThan875) setIsActive((isActive) => !isActive);
    onClick();
  }

  function handleMaxHeight(event) {
    if (!smallerThan875) return;
    if (isActive) return setMaxHeight(0);

    const { nextElementSibling } = event.currentTarget;
    window.target = nextElementSibling;
    setMaxHeight(nextElementSibling.scrollHeight);
  }

  return (
    <div>
      <CaseSelectorWrapper
        onClick={handleClick}
        className={isActive ? 'active' : ''}
        style={{
          backgroundImage: (image && `url(${image.url})`) || ''
        }}
      >
        <CaseTitle isActive={isActive}>
          {title}
          {smallerThan875 && (
            <img src={ChevronDown} alt="chevron icon" />
          )}
        </CaseTitle>
      </CaseSelectorWrapper>

      {smallerThan875 && (
        <CaseContentWrapper
          style={{
            maxHeight: maxHeight === null ? '100%' : maxHeight
          }}
        >
          <MarkdownPreview
            source={content}
            style={{ marginTop: 20, marginBottom: 30 }}
          />
        </CaseContentWrapper>
      )}
    </div>
  );
};

const ShowCase = ({ content, showCases }) => {
  const [currentCase, setCurrentCase] = useState(showCases[0]);

  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  const selectCase = (title) => {
    const showCase = showCases.find(
      (showCase) => showCase.title === title
    );
    if (!biggerThan875 && currentCase?.title === showCase.title)
      return setCurrentCase(null);
    setCurrentCase(showCase);
  };

  return (
    <TransformationWhiteWrapper>
      <TransformationBlock id="showCases">
        <TransformationBlockDiv>
          <span className="background-number">8</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>
        </TransformationBlockDiv>

        <CasesWrapper>
          <SectionWrapper>
            {showCases.map(({ title, content, image }) => (
              <CaseSelector
                key={title}
                title={title}
                content={content}
                image={image}
                isActive={title === currentCase?.title}
                onClick={() => selectCase(title)}
              />
            ))}
          </SectionWrapper>
          {biggerThan875 && (
            <>
              <DesktopListDivider style={{ marginRight: 30 }} />
              <SectionWrapper>
                <MarkdownPreview source={currentCase.content} />
              </SectionWrapper>
            </>
          )}
        </CasesWrapper>
      </TransformationBlock>
    </TransformationWhiteWrapper>
  );
};

export default ShowCase;
