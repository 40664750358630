import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import { useMediaPredicate } from 'react-media-hook';

import HeaderImage from 'assets/images/richcontent/hero_animation.png';

import {
  TransformationWrapper,
  TransformationBlock,
  HeadTitle,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomTransformationWrapper = styled(TransformationWrapper)`
  min-height: auto;
  background-color: white;
  padding: 0 16px;

  @media (min-width: 875px) {
    padding: 50px 0;
    margin-top: 60px;
  }

  @media (max-width: 874px) {
    margin-top: 50px;
  }
`;

const SubTitle = styled.h3`
  display: none;
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 0;

  color: ${colors.gray300};

  @media (max-width: 875px) {
    font-size: 18px;
    line-height: 27px;
  }

  @media (min-width: 875px) {
    display: block;
  }
`;

const smallImgAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-880px);
  }
  100% {
    transform: translateX(-880px);
  }
`;

const bigImgAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-274px);
  }
  100% {
    transform: translateX(-274px);
  }
`;

const whiteDivAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(-546px);
  }
  100% {
    transform: translateX(-546px);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const AnimationDiv = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
`;

const AnimationImage = styled.img`
  width: 319px;
  z-index: 1;
  height: ${(props) => (props.small ? '223px' : '293px')};
  animation: ${(props) =>
      props.small ? smallImgAnimation : bigImgAnimation}
    5000ms infinite alternate ease-in-out;
  animation-delay: 1000ms;
`;

const WhiteSeparator = styled.div`
  width: 46px;
  height: 223px;
  background: white;
  margin-left: -46px;
  animation: ${whiteDivAnimation} 5000ms infinite alternate
    ease-in-out;
  animation-delay: 1000ms;
  z-index: 2;
`;

const CustomTitleTextWrapper = styled(TitleTextWrapper)`
  ${(props) =>
    !props.skipAnimation &&
    css`
      animation: ${(props) => (props.fadeIn ? fadeIn : fadeOut)}
        5000ms infinite alternate ease-in-out both;
      animation-delay: 1000ms;
    `};
`;

const TransformationHeader = ({ content }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  return (
    <CustomTransformationWrapper isBottomDiagonalRight>
      <TransformationBlock
        style={(!biggerThan875 && { marginBottom: 30 }) || {}}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative'
          }}
        >
          <CustomTitleTextWrapper skipAnimation={!biggerThan875}>
            <LineAboveText />
            <HeadTitle style={{ paddingTop: 20 }}>
              {content.title}
            </HeadTitle>
          </CustomTitleTextWrapper>

          <CustomTitleTextWrapper
            fadeIn
            skipAnimation={!biggerThan875}
          >
            <LineAboveText isSubtitle />
            <SubTitle>{content.subtitle}</SubTitle>
          </CustomTitleTextWrapper>

          {biggerThan875 && (
            <AnimationDiv style={{ height: '100%' }}>
              <AnimationImage
                src={HeaderImage}
                alt="Mountains animation"
              />
              <WhiteSeparator />
              <AnimationImage
                src={HeaderImage}
                alt="Mountains animation"
                small
              />
            </AnimationDiv>
          )}
        </div>
      </TransformationBlock>
    </CustomTransformationWrapper>
  );
};

export default TransformationHeader;
