import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StyledWrapper } from 'assets/global/styled';
import {
  StyledCreations,
  StyledCreationsContainer,
  StyledCreationsSubtitle,
  StyledCreationsList,
  StyledCreationsItem
} from './styled';

const Creations = (props) => {
  const { titleCreation } = props.page;
  const { ateliware } = useStaticQuery(
    graphql`
      {
        ateliware {
          customers {
            id
            name
            website
            logo {
              alt
              title
              url
            }
          }
        }
      }
    `
  );

  const { customers } = ateliware;

  return (
    <React.Fragment>
      {customers.length > 0 && (
        <StyledCreations style={props.wrapperStyle}>
          <StyledWrapper>
            <StyledCreationsContainer>
              {titleCreation && (
                <StyledCreationsSubtitle>
                  {titleCreation}
                </StyledCreationsSubtitle>
              )}
              <StyledCreationsList>
                {customers.map((customer) => {
                  const { id, name, logo, website } = customer;

                  return (
                    <StyledCreationsItem key={id}>
                      {logo?.url && website && (
                        <a
                          href={website}
                          title={name}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={logo?.url}
                            width="auto"
                            height="42"
                            alt={name}
                          />
                        </a>
                      )}
                      {logo?.url && (
                        <img
                          src={logo?.url}
                          width="auto"
                          height="42"
                          alt={name}
                        />
                      )}
                    </StyledCreationsItem>
                  );
                })}
              </StyledCreationsList>
            </StyledCreationsContainer>
          </StyledWrapper>
        </StyledCreations>
      )}
    </React.Fragment>
  );
};

export default Creations;
