import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useMediaPredicate } from 'react-media-hook';
import MarkdownPreview from '@uiw/react-markdown-preview';

import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import ChevronDown from 'assets/images/richcontent/black-chevron-down.svg';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  BodyTitle,
  QuoteText,
  Text,
  ItemTitle,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText,
  DesktopListDivider
} from './styled';

const Collapsable = styled.div`
  padding-left: 30px;

  @media (max-width: 875px) {
    padding-left: 0px;
  }
`;

const CollapsableToggle = styled.button`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-left: 60px;

  @media (max-width: 874px) {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }

  img {
    width: 14px;
    margin-left: 14px;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
    transition: transform 0.3s ease-out;
  }

  ${(props) =>
    props.isOpen &&
    css`
      p {
        font-weight: bold !important;
      }
    `}
`;

const CollapsableItem = styled.div`
  text-align: left;
  position: relative;
  padding-bottom: 70px;

  @media (max-width: 875px) {
    box-shadow: none;
    padding-bottom: 50px;
  }

  ${ItemTitle} {
    font-family: ${fonts.primary};
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;

    @media (max-width: 875px) {
      flex-grow: 1;
      text-align: left;
      margin: 0;
      margin-left: 5px;
      line-height: 1.3em;
    }
  }

  &:last-child {
    box-shadow: none;
    padding-bottom: ${({ isOpen }) => (isOpen ? 0 : '30px')};
  }
`;

const CollapseableMarker = styled.div`
  position: relative;
  width: 58px;
  height: 58px;
  z-index: 3;
  flex-shrink: 0;
  margin-right: 18px;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 45px;

  @media (max-width: 875px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const CollapseableMarkerContent = styled.span`
  background: white;
  font-family: ${fonts.primary};
  font-size: 30px;
  font-weight: normal;
  z-index: 3;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CollapseableMarkerRed = styled.div`
  border: 1px solid ${colors.black};
  background: white;
  flex-shrink: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;

  ${(props) =>
    props.isOpen &&
    css`
      transform: rotate(45deg);
      border: 1px solid ${colors.red300};
    `}
`;

const CollapseContent = styled.div`
  max-height: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
`;

const CustomWrapper = styled(TransformationWhiteWrapper)`
  background-color: ${colors.white};
  color: ${colors.black};
  ${Text} {
    &:first-child {
      padding-top: 0;
    }
  }

  #mobile-list-wrapper {
    display: none;
  }

  @media (max-width: 874px) {
    #mobile-list-wrapper {
      display: block;
    }

    #desktop-list-wrapper {
      display: none;
    }
  }
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  @media (min-width: 875px) {
    padding-top: 80px;
  }
`;

const CollapsableWrapper = styled.div`
  @media (min-width: 875px) {
    border-right: 1px solid red;
    width: 60%;
  }

  .mobile-collapsible {
    @media (min-width: 875px) {
      display: none;
    }
  }
`;

const DesktopListWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const DesktopMarkerBorder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  border: 1px solid ${colors.black};
  transition: transform 0.5s;

  button:hover &,
  button.active & {
    transform: rotate(45deg);
    border: 1px solid ${colors.red300};
  }
`;

const DesktopListItemsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 40px;
      button {
        display: flex;
        align-items: center;
        background: none;
        outline: none;
        border: none;
        cursor: pointer;

        span {
          max-width: 250px;
          text-align: left;
          font-family: ${fonts.primary};
          font-size: 16px;
          line-height: 24px;
          color: ${colors.black};
        }
      }
    }
  }
`;

const DesktopListItemContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DesktopMarker = styled.div`
  width: 58px;
  height: 58px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 27px;
  font-family: ${fonts.primary};
  font-size: 30px;
  line-height: 45px;
`;

const CollapsableItemComponent = ({ index = 1, title, children }) => {
  const [isOpen, togglePanel] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  return (
    <CollapsableItem isOpen={isOpen}>
      <CollapsableToggle
        isOpen={isOpen}
        onClick={(event) => {
          const { nextElementSibling } = event.currentTarget;

          setMaxHeight(nextElementSibling.scrollHeight);
          togglePanel((isCurrentOpen) => !isCurrentOpen);
        }}
      >
        <CollapseableMarker>
          <CollapseableMarkerRed isOpen={isOpen} />
          <CollapseableMarkerContent>
            {index}
          </CollapseableMarkerContent>
        </CollapseableMarker>

        <ItemTitle>{title}</ItemTitle>
        <img src={ChevronDown} alt="isOpen icon" />
      </CollapsableToggle>

      <CollapseContent style={{ maxHeight: isOpen ? maxHeight : 0 }}>
        {children}
      </CollapseContent>
    </CollapsableItem>
  );
};

const DesktopList = ({ content }) => {
  const [activeItem, setActiveItem] = useState(0);
  const anchor = useRef(null);

  const handleItemChange = (index) => {
    const distanceFromTop = anchor.current.getBoundingClientRect()
      .top;
    const newScrollTop =
      document.documentElement.scrollTop + distanceFromTop;
    window.scroll({
      top: newScrollTop,
      behavior: 'smooth'
    });
    setActiveItem(index);
  };

  return (
    <div>
      <TitleTextWrapper width="50%">
        <span className="background-number">4</span>
        <LineAboveText />
        <SubHeadTitle>{content.title}</SubHeadTitle>
      </TitleTextWrapper>
      <div
        id="activators-scroll-anchor"
        style={{ paddingTop: 60, marginTop: -60 }}
        ref={anchor}
      ></div>
      <DesktopListWrapper id="desktop-list">
        <DesktopListItemsDiv>
          {content.content1 && (
            <MarkdownPreview
              style={{ marginBottom: 30 }}
              source={content.content1}
            />
          )}
          <ul>
            {content.list.map((item, index) => (
              <li>
                <button
                  onClick={() => handleItemChange(index)}
                  className={activeItem === index ? 'active' : ''}
                >
                  <DesktopMarker>
                    <DesktopMarkerBorder />
                    {index + 1}
                  </DesktopMarker>
                  <span
                    style={
                      activeItem === index ? { fontWeight: 600 } : {}
                    }
                  >
                    {item.title}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </DesktopListItemsDiv>
        <DesktopListDivider />
        <DesktopListItemContentDiv>
          <MarkdownPreview
            source={content.list[activeItem].content}
          />
        </DesktopListItemContentDiv>
      </DesktopListWrapper>

      <div style={{ marginTop: 60 }}>
        <QuoteText>{content.highlight}</QuoteText>
      </div>
    </div>
  );
};

const Activators = ({ content }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  return (
    <CustomWrapper id="activators">
      <CustomTransformationBlock>
        <TransformationBlockDiv width="100%">
          <div id="desktop-list-wrapper">
            <DesktopList content={content} />
          </div>
          <div id="mobile-list-wrapper">
            <CollapsableWrapper>
              <span className="background-number">4</span>
              <LineAboveText />
              <SubHeadTitle>{content.title}</SubHeadTitle>
              <MarkdownPreview
                style={{ marginBottom: 30 }}
                source={content.content1}
              />
              <Collapsable className="mobile-collapsable">
                {content.list.map((listItem, index) => (
                  <CollapsableItemComponent
                    key={listItem.title}
                    index={index + 1}
                    title={listItem.title}
                  >
                    <MarkdownPreview
                      style={{ marginTop: 30 }}
                      source={listItem.content}
                    />
                  </CollapsableItemComponent>
                ))}
              </Collapsable>
            </CollapsableWrapper>
            <MarkdownPreview
              style={{ marginTop: biggerThan875 ? 30 : 0 }}
              source={content.content2}
            />
            <BodyTitle style={{ marginBottom: 0 }}>
              {content.highlight}
            </BodyTitle>
          </div>
        </TransformationBlockDiv>
      </CustomTransformationBlock>
    </CustomWrapper>
  );
};

export default Activators;
