import React, { useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useMediaPredicate } from 'react-media-hook';
import colors from 'assets/global/colors';
import MarkdownPreview from '@uiw/react-markdown-preview';
import OurPartners from './OurPartners';

import { usePosition } from 'templates/RichContent/hooks/usePosition';
import DiscoveryBackground from 'assets/images/richcontent/discovery_background.png';
import fonts from 'assets/global/fonts';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  QuoteText,
  BigQuoteText,
  Text,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;

  @media (min-width: 875px) {
    flex-direction: row;
  }
`;

const CustomTransformationBlockDiv = styled(TransformationBlockDiv)`
  position: relative;

  img {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
  }

  @media (min-width: 875px) {
    margin-left: 97px;

    img {
      width: 874px;
      height: 804px;
    }
  }
`;

const CarouselContainer = styled.div`
  border: solid 1px ${colors.gray300};
  margin-bottom: 34px;
  margin-top: 34px;
  position: relative;

  @media (max-width: 875px) {
    width: 100%;
  }
`;

const Relative = styled.div`
  position: relative;
  overflow: hidden;
`;

const CarouselHorizontalContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: center;
  }
`;

const CarouselListItem = styled.div`
  margin-left: 1rem;
  width: 100%;
  padding: 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 60px;
  }
`;

const ControlsContainer = styled.div`
  max-width: 45px;
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Control = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e5e5e5;

  ${({ active }) =>
    active &&
    `
    background: ${colors.black};
    transition: background ease-in-out .3s;
  `}
`;

const CustomText = styled(Text)`
  margin-top: 24px;
  padding: 0;
  font-family: ${fonts.secondary};
  font-weight: 300;
  font-size: 14px;
  text-align: center;

  @media (max-width: 875px) {
    font-size: 14px;
    line-height: 25px;
  }
`;

const Carousel = ({ labItems }) => {
  const ref = useRef();
  const { scrollRight, scrollToStart, currentIndex } = usePosition(
    ref
  );

  const runCarroselAuto = useCallback(() => {
    if (currentIndex === 0) {
      scrollRight();
    }

    if (currentIndex === 1) {
      scrollToStart();
    }
  }, [currentIndex, scrollRight, scrollToStart]);

  useEffect(() => {
    const looper = setInterval(() => runCarroselAuto(), 3000);
    return () => clearInterval(looper);
  });

  return (
    <CarouselContainer>
      <Relative>
        <CarouselHorizontalContainer ref={ref}>
          {labItems.map((labItem) => {
            return (
              <CarouselListItem key={labItem.content}>
                <img src={labItem.logo.url} alt={labItem.content} />
                <CustomText>{labItem.content}</CustomText>
              </CarouselListItem>
            );
          })}
        </CarouselHorizontalContainer>

        <ControlsContainer>
          <Control
            aria-label="minestore"
            active={currentIndex === 0}
          />
          <Control aria-label="pipefy" active={currentIndex === 1} />
        </ControlsContainer>
      </Relative>
    </CarouselContainer>
  );
};

const WeAreAteliware = ({ content, labItems }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  return (
    <TransformationWhiteWrapper id="weAreAteliware" isTopDiagonalLeft>
      <CustomTransformationBlock
        style={{
          position: 'relative',
          marginBottom: biggerThan875 ? 0 : 30,
          paddingBottom: 0
        }}
      >
        <TransformationBlockDiv>
          <span className="background-number">7</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>

          <MarkdownPreview source={content.content1} />

          <QuoteText>{content.highlight1}</QuoteText>

          <MarkdownPreview source={content.content2} />
        </TransformationBlockDiv>
        <CustomTransformationBlockDiv>
          {biggerThan875 && (
            <img
              src={content.image.url || DiscoveryBackground}
              alt="discovery process"
              style={{ transform: 'translateY(-50%)', top: '50%' }}
            />
          )}
        </CustomTransformationBlockDiv>
      </CustomTransformationBlock>

      <OurPartners content={{ title: content.partnerTitle }} />

      <CustomTransformationBlock
        style={!biggerThan875 ? { marginBottom: 80 } : {}}
      >
        <TransformationBlockDiv>
          <BigQuoteText
            style={{
              maxWidth: 500,
              marginBottom: biggerThan875 ? 0 : 30
            }}
          >
            {content.highlight2}
          </BigQuoteText>
        </TransformationBlockDiv>
        <TransformationBlockDiv>
          <MarkdownPreview source={content.content3} />
          <Carousel labItems={labItems} />
          <MarkdownPreview source={content.content4} />
        </TransformationBlockDiv>
      </CustomTransformationBlock>
    </TransformationWhiteWrapper>
  );
};

export default WeAreAteliware;
