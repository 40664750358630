import styled from 'styled-components';
import colors from 'assets/global/colors';
// import fonts from 'assets/global/fonts';

export const StyledContentLanding = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${colors.white};

  strong {
    font-weight: 600;
  }
`;
