import styled from 'styled-components';

export const StyledLogo = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  z-index: 100;

  img {
    display: block;
    width: 55px;
    height: auto;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 980px) {
      width: 50px;
    }
  }
`;
