import React from 'react';
import { Link } from 'gatsby';
import { StyledLogo } from './styled';

const Logo = ({ baseURL }) => {
  return (
    <StyledLogo>
      <Link to={baseURL} title="ateliware">
        <img
          src="https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV"
          alt="Ateliware"
        />
      </Link>
    </StyledLogo>
  );
};

export default Logo;
