import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import styled from 'styled-components';

import DotPatterRedBG from 'assets/images/richcontent/background/dot-pattern-red.png';
import transformImage from 'assets/images/richcontent/section3images.png';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  BodyTitle,
  TransformationBlockDiv,
  LineAboveText,
  TitleTextWrapper
} from './styled';

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;
  padding-top: 40px;

  @media (min-width: 875px) {
    flex-direction: row;
    padding-top: 80px;
  }
`;

const ImageWrapper = styled(TransformationBlockDiv)`
  margin-right: 100px;

  @media (max-width: 874px) {
    display: none;
  }
`;

const WhyTransform = ({ content }) => {
  return (
    <TransformationWhiteWrapper id="whyTransform">
      <CustomTransformationBlock>
        <ImageWrapper style={{ marginRight: 100 }}>
          <img
            src={content?.image?.url || transformImage}
            alt="section images"
            style={{ width: '100%' }}
          />
        </ImageWrapper>
        <TransformationBlockDiv style={{ flexShrink: 0 }}>
          <span className="background-number">1</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>

          <MarkdownPreview source={content.content1} />

          <BodyTitle>{content.highlight}</BodyTitle>

          <MarkdownPreview source={content.content2} />
        </TransformationBlockDiv>
      </CustomTransformationBlock>
    </TransformationWhiteWrapper>
  );
};

export default WhyTransform;
