import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useMediaPredicate } from 'react-media-hook';
import MarkdownPreview from '@uiw/react-markdown-preview';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import FirstRevolutionImage from 'assets/images/richcontent/first_revolution.png';
import SecondRevolutionImage from 'assets/images/richcontent/second_revolution.png';
import ThirdRevolutionImage from 'assets/images/richcontent/third_revolution.png';
import FourthRevolutionImage from 'assets/images/richcontent/fourth_revolution.png';

import ChevronDown from 'assets/images/richcontent/white-chevron.svg';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  BigQuoteText,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomWrapper = styled(TransformationWhiteWrapper)`
  @media (max-width: 875px) {
    padding: 0 16px;
  }
`;

const Divider = styled.div`
  width: 92px;
  border-top: 2px solid #333333;
  margin-left: auto;
  margin-right: auto;
  margin-top: -75px;
  margin-bottom: 50px;

  @media (max-width: 874px) {
    display: none;
  }
`;

const CustomTransformationBlockDiv = styled(TransformationBlockDiv)`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 875px) {
    flex-direction: row;
    align-items: center;
  }
`;

const ContentWrapper = styled.div`
  @media (min-width: 768px) {
    flex-basis: 50%;
    flex-shrink: 0;
  }
`;

const RevolutionImagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  gap: 15px;
  width: 900px;
  height: 476px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 135px;
  margin-bottom: 130px;

  img {
    width: 100%;
    min-height: 208px;
    height: 100%;
    margin-bottom: 0;
    position: relative;
    object-fit: cover;
  }

  .image-wrapper {
    position: relative;
    transition: transform 0.2s ease-out;
    transition-property: transform, filter;
    filter: grayscale(1);

    .content-wrapper {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: transform 200ms ease-out;
      padding: 25px;
      opacity: 50%;
    }

    .year {
      display: block;
      font-weight: 500;
      font-family: ${fonts.primary};
      font-size: 30px;
      color: white;
      text-align: center;
    }

    &.active {
      transform: scale(1.3);
      filter: grayscale(0);

      .content-wrapper {
        opacity: 100%;
      }

      .year {
        font-weight: 600;
      }
    }
  }
`;

const Collapsable = styled.div`
  padding-left: 0px;
  margin-left: -42px;
`;

const CollapsableToggle = styled.button`
  width: 100%;
  height: 83px;
  display: flex;
  justify-content: flex-start;
  border: none;
  background: transparent;
  cursor: pointer;
  position: relative;

  div {
    ${(props) =>
      props.isOpen
        ? css`
            background: linear-gradient(
              to bottom,
              rgba(0, 47, 75, 0.5) 0%,
              rgba(220, 66, 37, 0.5) 100%
            );
          `
        : css`
            background: #000000;
          `};
    width: 100%;
    height: 83px;

    img {
      width: 100%;
      height: 83px;
      object-fit: cover;
    }
  }

  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: none;
    width: 100%;
    height: 100%;

    z-index: 5;
    position: absolute;
    right: 8%;
    top: 50%;
    transform: translateY(-50%);
    color: ${colors.white};
    font-family: ${fonts.primary};
    font-weight: 600;
    font-size: 28px;

    img {
      width: 14px;
      height: 10px;
      margin-left: 10px;
      transform: ${({ isOpen }) =>
        isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};
      transition: transform 0.3s ease-out;
    }
  }

  .image-wrapper {
    position: relative;
    filter: grayscale(1);
    transition: filter 0.2 ease-out;

    &.active {
      filter: grayscale(0);
    }
  }
`;

const CollapsableItem = styled.div`
  position: relative;
  margin-bottom: 6px;

  &:last-child {
    box-shadow: none;
    padding-bottom: 0;
  }
`;

const CollapseContent = styled.div`
  width: 100%;
  margin-left: 42px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ isOpen, scrollHeight }) =>
    isOpen
      ? css`
          max-height: ${scrollHeight}px;
        `
      : css`
          max-height: 0;
        `}
`;

const CollapsableItemComponentTitle = styled.h2`
  font-family: ${fonts.primary};
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;

  @media (min-width: 875px) {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 14px;
    color: white !important;
  }
`;

const CustomBigQuoteText = styled(BigQuoteText)`
  margin-top: 40px;

  @media (min-width: 875px) {
    margin-top: 0;
    margin-left: 80px;
  }
`;

const CollapsableItemComponent = ({
  image,
  children,
  year,
  isOpen: isOpenProp
}) => {
  const [isOpen, togglePanel] = useState(isOpenProp);
  const toggleRef = useRef();
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (isOpen) setMaxHeight(toggleRef.current.scrollHeight);
  }, [isOpen]);

  return (
    <CollapsableItem isOpen={isOpen}>
      <CollapsableToggle
        isOpen={isOpen}
        image={image}
        onClick={(event) => {
          const { nextElementSibling } = event.currentTarget;

          if (!isOpen) setMaxHeight(nextElementSibling.scrollHeight);
          togglePanel((isCurrentOpen) => !isCurrentOpen);
        }}
      >
        <div className="wrapper">
          <span>{year}</span>
          <img src={ChevronDown} alt="chevron icon" />
        </div>
        <div className={`image-wrapper ${isOpen ? 'active' : ''}`}>
          <img src={image} alt="Revolution" />
        </div>
      </CollapsableToggle>

      <CollapseContent
        isOpen={isOpen}
        ref={toggleRef}
        style={{ maxHeight: isOpen ? maxHeight : 0 }}
      >
        {children}
      </CollapseContent>
    </CollapsableItem>
  );
};

const MobileIndustrialRevolutions = ({ data }) => {
  const markdownStyle = { maxWidth: 300, marginBottom: 20 };

  return (
    <Collapsable>
      <CollapsableItemComponent
        image={FirstRevolutionImage}
        year="1784"
        isOpen={true}
      >
        <CollapsableItemComponentTitle>
          {data[0].title}
        </CollapsableItemComponentTitle>
        <MarkdownPreview
          style={markdownStyle}
          source={data[0].content}
        />
      </CollapsableItemComponent>

      <CollapsableItemComponent
        image={SecondRevolutionImage}
        year="1870"
      >
        <CollapsableItemComponentTitle>
          {data[1].title}
        </CollapsableItemComponentTitle>
        <MarkdownPreview
          style={markdownStyle}
          source={data[1].content}
        />
      </CollapsableItemComponent>

      <CollapsableItemComponent
        image={ThirdRevolutionImage}
        year="1969"
      >
        <CollapsableItemComponentTitle>
          {data[2].title}
        </CollapsableItemComponentTitle>
        <MarkdownPreview
          style={markdownStyle}
          source={data[2].content}
        />
      </CollapsableItemComponent>

      <CollapsableItemComponent
        image={FourthRevolutionImage}
        year="2021"
      >
        <CollapsableItemComponentTitle>
          {data[3].title}
        </CollapsableItemComponentTitle>
        <MarkdownPreview
          style={markdownStyle}
          source={data[3].content}
        />
      </CollapsableItemComponent>
    </Collapsable>
  );
};

const DesktopIndustrialRevolutions = ({ data }) => {
  const [active, setActive] = useState(0);

  return (
    <>
      <RevolutionImagesGrid>
        <DesktopRevolution
          year={1784}
          isActive={active === 0}
          data={data}
          onMouseEnter={() => setActive(0)}
          imageUrl={FirstRevolutionImage}
          altText="First revolution"
          transformOrigin="bottom right"
          index={0}
        />

        <DesktopRevolution
          year={1870}
          isActive={active === 1}
          data={data}
          onMouseEnter={() => setActive(1)}
          imageUrl={SecondRevolutionImage}
          altText="Second revolution"
          transformOrigin="bottom left"
          index={1}
        />

        <DesktopRevolution
          year={1969}
          isActive={active === 2}
          data={data}
          onMouseEnter={() => setActive(2)}
          imageUrl={ThirdRevolutionImage}
          altText="Third revolution"
          transformOrigin="top right"
          index={2}
        />

        <DesktopRevolution
          year={2021}
          isActive={active === 3}
          data={data}
          onMouseEnter={() => setActive(3)}
          imageUrl={FourthRevolutionImage}
          altText="Fourth revolution"
          transformOrigin="top left"
          index={3}
        />
      </RevolutionImagesGrid>
    </>
  );
};

const DesktopRevolution = ({
  year,
  isActive,
  content,
  onMouseEnter,
  imageUrl,
  altText,
  transformOrigin,
  data,
  index
}) => {
  return (
    <div
      className={`image-wrapper ${isActive ? 'active' : ''}`}
      style={{ transformOrigin }}
      onMouseEnter={onMouseEnter}
    >
      <div className="content-wrapper">
        <span className="year">{year}</span>

        <CollapsableItemComponentTitle>
          {data[index].title}
        </CollapsableItemComponentTitle>
        <MarkdownPreview
          style={{
            fontSize: 16,
            textAlign: 'center',
            color: 'white'
          }}
          source={data[index].content}
        />
      </div>

      <img alt={altText} src={imageUrl} />
    </div>
  );
};

const HowToPrepare = ({ content }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');
  function IndustrialRevolutions() {
    if (biggerThan875)
      return <DesktopIndustrialRevolutions data={content.list} />;
    return <MobileIndustrialRevolutions data={content.list} />;
  }

  return (
    <CustomWrapper id="howToPrepare" isBottomDiagonalLeft>
      <TransformationBlock style={{ marginBottom: 40 }}>
        <TransformationBlockDiv>
          <span className="background-number">5</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>
        </TransformationBlockDiv>
        <CustomTransformationBlockDiv>
          <ContentWrapper>
            <MarkdownPreview source={content.content1} />

            <MarkdownPreview
              style={{ marginTop: 30 }}
              source={content.content3}
            />
          </ContentWrapper>
          <CustomBigQuoteText>{content.highlight}</CustomBigQuoteText>
        </CustomTransformationBlockDiv>
      </TransformationBlock>
      <TransformationBlock
        style={biggerThan875 ? {} : { paddingTop: 0 }}
      >
        <div style={{ textAlign: biggerThan875 ? 'center' : 'left' }}>
          <Divider />
          <MarkdownPreview
            source={content.content2}
            style={{ textAlign: 'center' }}
          />

          <IndustrialRevolutions />
        </div>
      </TransformationBlock>
    </CustomWrapper>
  );
};

export default HowToPrepare;
