import React from 'react';
import styled from 'styled-components';
import MarkdownPreview from '@uiw/react-markdown-preview';

import colors from 'assets/global/colors';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText,
  QuoteBlock,
  BigQuoteText
} from './styled';

const CustomTransformationWhiteWrapper = styled(
  TransformationWhiteWrapper
)`
  background-color: ${colors.white};
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;
  color: ${colors.black};

  @media (min-width: 875px) {
    flex-direction: row;
    align-items: center;
  }
`;

const StartToday = ({ content }) => {
  return (
    <CustomTransformationWhiteWrapper
      id="startToday"
      isBottomDiagonalRight
    >
      <CustomTransformationBlock
        style={{ paddingBottom: 0, marginBottom: 0 }}
      >
        <TransformationBlockDiv>
          <span className="background-number">2</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>
        </TransformationBlockDiv>
      </CustomTransformationBlock>

      <CustomTransformationBlock style={{ paddingTop: 0 }}>
        <TransformationBlockDiv>
          <MarkdownPreview source={content.content1} />
          <div style={{ marginBottom: '16px' }}></div>
          <MarkdownPreview source={content.content2} />
        </TransformationBlockDiv>
        <TransformationBlockDiv>
          <QuoteBlock style={{ marginTop: 20 }}>
            <h6
              style={{
                fontSize: 16,
                color: colors.gray300,
                marginTop: 0,
                marginBottom: 10,
                fontWeight: 'normal'
              }}
            >
              {content.highlightMedium}
            </h6>
            <BigQuoteText>{content.highlight}</BigQuoteText>
          </QuoteBlock>
        </TransformationBlockDiv>
      </CustomTransformationBlock>
    </CustomTransformationWhiteWrapper>
  );
};

export default StartToday;
