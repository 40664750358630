import React, { useEffect, useCallback, useRef } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import { usePosition } from 'templates/RichContent/hooks/usePosition';

import { useMediaPredicate } from 'react-media-hook';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  TextWrapper,
  SubHeadTitle,
  BodyTitle,
  Text,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomTransformationBlock = styled(TransformationBlock)`
  padding-bottom: 0;

  @media (max-width: 874px) {
    margin-bottom: 40px;
  }
`;

const CarouselContainer = styled.div`
  border: solid 1px ${colors.gray300};
  margin: 0 auto 34px auto;
  margin-bottom: 34px;
  position: relative;
  width: 562px;

  @media (max-width: 875px) {
    width: 100%;
  }
`;

const Relative = styled.div`
  position: relative;
  overflow: hidden;
`;

const CarouselHorizontalContainer = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: center;
  }
`;

const CarouselListItem = styled.div`
  margin-left: 1rem;
  width: 100%;
  padding: 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ControlsContainer = styled.div`
  max-width: 45px;
  margin: 0 auto 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Control = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #e5e5e5;

  ${({ active }) =>
    active &&
    `
    background: ${colors.black};
    transition: background ease-in-out .3s;
  `}
`;

const CustomSubHeadTitle = styled(SubHeadTitle)`
  @media (max-width: 874px) {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 40px;
  }
`;

const CustomText = styled(Text)`
  margin: 0 0 8px 0;
  padding: 0;
  font-family: ${fonts.secondary};
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  text-align: center;

  @media (max-width: 875px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

const TextSource = styled(Text)`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 16px;
`;

const Carousel = ({ slider }) => {
  const ref = useRef();
  const { scrollRight, scrollToStart, currentIndex } = usePosition(
    ref
  );

  const runCarroselAuto = useCallback(() => {
    if (currentIndex === 0) {
      scrollRight();
    }

    if (currentIndex === 1) {
      scrollRight();
    }

    if (currentIndex === 2) {
      scrollToStart();
    }
  }, [currentIndex, scrollRight, scrollToStart]);

  useEffect(() => {
    const looper = setInterval(() => runCarroselAuto(), 5000);
    return () => clearInterval(looper);
  });

  return (
    <CarouselContainer>
      <Relative>
        <CarouselHorizontalContainer ref={ref}>
          {slider.map((slide) => (
            <CarouselListItem key={slide.source}>
              <CustomText>{slide.quote}</CustomText>

              <TextSource>{slide.source}</TextSource>
            </CarouselListItem>
          ))}
        </CarouselHorizontalContainer>

        <ControlsContainer>
          <Control aria-label="autocar" active={currentIndex === 0} />
          <Control
            aria-label="techradar"
            active={currentIndex === 1}
          />
          <Control aria-label="wired" active={currentIndex === 2} />
        </ControlsContainer>
      </Relative>
    </CarouselContainer>
  );
};

const UncertainFuture = ({ content }) => {
  const smallerThan875 = useMediaPredicate('(max-width: 874px)');

  return (
    <TransformationWhiteWrapper>
      <CustomTransformationBlock>
        <TitleTextWrapper width="100%" style={{ marginBottom: 20 }}>
          <LineAboveText isSubtitle />
          <CustomSubHeadTitle>{content.title}</CustomSubHeadTitle>
        </TitleTextWrapper>

        <Carousel slider={content.slider} />
        <TextWrapper
          style={
            (smallerThan875 && {
              paddingTop: 10,
              marginBottom: 40
            }) ||
            {}
          }
        >
          <MarkdownPreview source={content.content1} />
          <BodyTitle>{content.highlight}</BodyTitle>
          <MarkdownPreview source={content.content2} />
        </TextWrapper>
      </CustomTransformationBlock>
    </TransformationWhiteWrapper>
  );
};

export default UncertainFuture;
