import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import BlockBG from 'assets/images/richcontent/background/Rectangle.png';
import ChevronDownWhiteIcon from 'assets/images/richcontent/white-chevron.svg';

import { useMediaPredicate } from 'react-media-hook';

import {
  TransformationWrapper,
  TransformationBlock,
  SubHeadTitle
} from './styled';

const CustomTransformationWrapper = styled(TransformationWrapper)`
  background-size: cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  min-height: auto;
  background-color: ${colors.gray200};

  @media (max-width: 875px) {
    padding: 0 16px;
    background-image: none !important;
  }
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  max-width: 1243px;

  @media (max-width: 874px) {
    margin-bottom: 0 !important;
  }
`;

const CustomSubHeadTitle = styled(SubHeadTitle)`
  text-transform: uppercase;

  @media (max-width: 874px) {
    margin-left: -15px !important;
  }
`;

const ListContainer = styled.ol`
  columns: 2;
  column-gap: 90px;
  padding-left: 0;

  @media (max-width: 875px) {
    columns: 1;
    column-gap: 0;
  }
`;

const ListItem = styled.li`
  text-align: left;
  margin-left: 10px;
  margin-bottom: 18px;
  padding: 8px;
  font-family: ${fonts.secondary};
  color: white;
  font-size: 16px;
  font-weight: 600;

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 874px) {
    margin-left: 20px;
  }
`;

const CustomWrapperFixed = styled(CustomTransformationWrapper)`
  background-image: none !important;
  background-color: #333333 !important;
  position: fixed !important;
  top: 0;
  z-index: 10000;
  height: 42px;
  overflow-y: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
      overflow-y: scroll;
      height: 100%;

      @media(min-width: 874px) {
        height: auto;
      }
    `}

  ${SubHeadTitle} {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    box-shadow: none;
    text-shadow: none;
    cursor: pointer;
    position: relative;

    &::after {
      content: '';
      color: white;
      width: 18px;
      min-height: 10px;
      background: url(${ChevronDownWhiteIcon}) no-repeat;
      top: 5px;
      margin-left: 8px;
      position: absolute;
      transform: ${({ isOpen }) =>
        isOpen ? 'rotate(0deg)' : 'rotate(180deg)'};
      transition: transform ease 0.2s;
    }
  }

  ${CustomTransformationBlock} {
    height: 0;
    padding: 10px;

    ${({ isOpen }) =>
      isOpen &&
      `
        height: auto;
        
        @media (max-width: 875px) {
          height: 100%;
        }
    `}
  }
`;

const SummaryContent = ({ onToggleSummary = () => {}, content }) => {
  return (
    <CustomTransformationBlock>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CustomSubHeadTitle
          style={{ color: 'white' }}
          onClick={onToggleSummary}
        >
          {content.title}
        </CustomSubHeadTitle>
      </div>

      <ListContainer>
        {content.subtitle.map((item) => (
          <ListItem key={item.link}>
            <a href={item.link} onClick={onToggleSummary}>
              {item.content}
            </a>
          </ListItem>
        ))}
      </ListContainer>
    </CustomTransformationBlock>
  );
};

const Summary = ({ content }) => {
  const ref = useRef();
  const [shouldBeFixed, setSummaryFixed] = useState(false);
  const [isOpen, setSummaryOpen] = useState(false);

  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  const toggleSummary = () => setSummaryOpen(!isOpen);

  const handleOnScroll = (event) => {
    const scrollTop = Math.round(
      ref.current.getBoundingClientRect().top
    );

    const summaryHeight = Math.round(
      ref.current.getBoundingClientRect().height
    );

    if (shouldBeFixed && summaryHeight > scrollTop * -1) {
      setSummaryFixed(false);
    }

    if (!shouldBeFixed && summaryHeight <= scrollTop * -1) {
      setSummaryFixed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleOnScroll);

    return () => {
      window.removeEventListener('scroll', handleOnScroll);
    };
  });

  return (
    <>
      {shouldBeFixed && (
        <CustomWrapperFixed isDiamondBackground isOpen={isOpen}>
          <SummaryContent
            onToggleSummary={toggleSummary}
            content={content}
          />
        </CustomWrapperFixed>
      )}

      <CustomTransformationWrapper
        ref={ref}
        style={
          (content.backgroundImage && {
            backgroundImage: `url(${content.backgroundImage.url})`
          }) ||
          {}
        }
      >
        <SummaryContent content={content} />
      </CustomTransformationWrapper>
    </>
  );
};

export default Summary;
