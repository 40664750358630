import React from 'react';
import styled from 'styled-components';

import DotPatterGrayBG from 'assets/images/richcontent/background/dot-pattern-gray.png';

import MarkdownPreview from '@uiw/react-markdown-preview';
import { useMediaPredicate } from 'react-media-hook';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  TransformationBlockDiv,
  SubHeadTitle,
  Text,
  QuoteText,
  TitleTextWrapper,
  LineAboveText
} from './styled';

const CustomWrapper = styled(TransformationWhiteWrapper)`
  @media (min-width: 875px) {
    background: url(${DotPatterGrayBG}) no-repeat;
    background-position-x: 110%;
    background-position-y: -25%;
    padding-bottom: 80px;
  }

  ${Text} {
    &:first-child {
      padding-top: 0;
    }
  }

  ${TransformationBlock} {
    &:first-child {
      padding-bottom: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;

  @media (max-width: 874px) {
    flex-direction: column;
  }
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;
  padding: 0;

  &:first-child {
    margin-bottom: 20px;
  }

  ul {
    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  @media (max-width: 874px) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 875px) {
    flex-basis: 50%;
    margin-bottom: 0;

    &:first-child {
      margin-right: 80px;
    }
  }
`;

const SurvivingMatter = ({ content }) => {
  const biggerThan875 = useMediaPredicate('(min-width: 875px)');

  return (
    <CustomWrapper id="survivingMatter">
      <TransformationBlock
        style={(!biggerThan875 && { marginBottom: 0 }) || {}}
      >
        <TransformationBlockDiv style={{ width: '100%' }}>
          <span className="background-number">9</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>
        </TransformationBlockDiv>

        <ContentWrapper>
          <CustomTransformationBlock>
            <MarkdownPreview source={content.content1} />

            <QuoteText>{content.highlight1}</QuoteText>

            <MarkdownPreview source={content.content2} />
          </CustomTransformationBlock>

          <CustomTransformationBlock>
            <MarkdownPreview source={content.content3} />

            <QuoteText>{content.highlight2}</QuoteText>

            <MarkdownPreview source={content.content4} />
          </CustomTransformationBlock>
        </ContentWrapper>
      </TransformationBlock>
    </CustomWrapper>
  );
};

export default SurvivingMatter;
