import React from 'react';
import styled from 'styled-components';
import MarkdownPreview from '@uiw/react-markdown-preview';

import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import BlockBG from 'assets/images/richcontent/background/Rectangle.png';

import { useMediaPredicate } from 'react-media-hook';

import {
  TransformationWrapper,
  TransformationBlock,
  AltTitle,
  QuoteText
} from './styled';

const CONSUMER_LINK =
  'https://koi-3QNLNYI0LM.marketingautomation.services/net/m?md=rWq8AQPeVUg8NhjMdbT9gYk2oHqwGNaq';
const TRANSFORMATION_LINK =
  'https://koi-3QNLNYI0LM.marketingautomation.services/net/m?md=gU2oSReyq4wSFf3MAwq3l9HGi4%2Fp9EJn';
const EDUCATION_LINK =
  'https://koi-3QNLNYI0LM.marketingautomation.services/net/m?md=b3kgGDLFSvGpc1M0LIemhjLNx8%2BOGLLc';

const CustomTransformationWrapper = styled(TransformationWrapper)`
  background-size: cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  min-height: auto;
  background-color: ${colors.gray200};

  @media (max-width: 875px) {
    padding: 0 20px;
    background-image: none !important;
  }

  @media (min-width: 875px) {
    background-image: url(${BlockBG});
  }
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  max-width: 812px;

  &:last-child {
    padding-bottom: 100px;
  }

  @media (max-width: 875px) {
    margin-bottom: 60px;

    &:first-child {
      padding-bottom: 20px;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ol {
    list-style-type: none;

    margin: 0;
    margin-left: 4em;
    padding: 0;

    counter-reset: li-counter;

    li {
      position: relative;
      min-height: 86px;
      color: ${colors.white};
      font-family: ${fonts.secondary};
      font-size: 12px;
      font-weight: 400;
    }

    li:before {
      color: ${colors.red300};
      font-family: ${fonts.primary};
      font-size: 56px;
      font-weight: bold;
      position: absolute;
      top: -0.3em;
      left: -1.33em;
      content: counter(li-counter);
      counter-increment: li-counter;
    }
  }

  div {
    display: none;
  }

  @media (max-width: 874px) {
    ol li:before {
      left: -1em;
    }
  }

  @media (min-width: 875px) {
    margin-bottom: 30px;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    max-width: 812px;
    div {
      display: flex;
    }
    ol {
      display: none;
    }
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 50px;

  span {
    font-family: ${fonts.primary};
    color: ${colors.red300};
    font-weight: bold;
    font-size: 56px;
    line-height: 56px;
    margin-right: 10px;
  }

  div {
    font-family: ${fonts.secondary};
    color: ${colors.white};
    font-size: 12px;
  }

  h5 {
    font-weight: normal;
    margin: 0;
  }
`;

const List = ({ reasons }) => {
  return (
    <ListContainer>
      <ol>
        {reasons.map((reason, index) => (
          <li key={reason}>{reason}</li>
        ))}
      </ol>
      {reasons.map((reason, index) => (
        <ListItem key={reason} className="desktop-list">
          <span>{`${index + 1}. `}</span>
          <h5>{reason}</h5>
        </ListItem>
      ))}
    </ListContainer>
  );
};

const YouShouldInvest = ({ content }) => {
  return (
    <CustomTransformationWrapper
      isDiamondBackground
      isBottomDiagonalLeft
      style={
        (content.backgroundImage && {
          backgroundImage: `url(${content.backgroundImage.url})`
        }) ||
        {}
      }
    >
      <CustomTransformationBlock>
        <AltTitle>{content.title}</AltTitle>
        <List reasons={content.reasonsList} />

        <MarkdownPreview source={content.content} />

        <QuoteText>{content.highlight1}</QuoteText>
      </CustomTransformationBlock>
    </CustomTransformationWrapper>
  );
};

export default YouShouldInvest;
