import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledCreations = styled.section`
  display: block;
  padding: 30px 0;
  margin: 30px 0;

  @media screen and (max-width: 980px) {
    padding: 0px;
    margin: 0px;
  }
`;

export const StyledCreationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const StyledCreationsSubtitle = styled.h2`
  position: relative;
  width: 100%;
  margin: 0 0 50px 0;
  padding: 20px 0;
  font-family: ${fonts.primary};
  font-size: 24px;
  font-weight: 300;
  color: ${colors.gray300};

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 2px;
    background-color: ${colors.gray900};
    content: '';
  }

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 520px) {
    font-size: 16px;
  }
`;

export const StyledCreationsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  padding: 0;
  margin: -10px 0;
  list-style-type: none;

  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 580px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 360px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledCreationsItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;
