import styled from 'styled-components';
import { lighten } from 'polished';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledHeaderLanding = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 60px;

  @media (max-width: 875px) {
    padding: 12px 26px;
  }
`;

export const LandingButton = styled.a`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  font-family: ${fonts.primary};
  font-size: 14px;
  color: ${colors.white};
  text-decoration: none;
  border: 1px solid ${colors.red300};
  border-radius: 2px;
  background-color: ${colors.red300};
  transition: all 0.2s ease-out;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  &:after {
    display: inline-block;
    margin-left: 10px;
    content: '→';
    font-size: 24px;
  }

  &:hover {
    color: ${colors.white};
    background-color: ${lighten(0.2, colors.red300)};
  }
`;
