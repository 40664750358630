import React from 'react';
import styled from 'styled-components';
import MarkdownPreview from '@uiw/react-markdown-preview';

import colors from 'assets/global/colors';

import {
  TransformationWhiteWrapper,
  TransformationBlock,
  SubHeadTitle,
  Text,
  TransformationBlockDiv,
  TitleTextWrapper,
  LineAboveText,
  QuoteText
} from './styled';

const HARVARD_LINK =
  'https://www.cio.com/article/3122806/digital-laggards-must-harness-data-or-get-left-behind.html';
const IFS_LINK =
  'https://www.enr.com/articles/49691-percent-of-businesses-increase-or-maintain-digital-transformation-spend-amid-pandemic-ifs-study-says';

const CustomWrapper = styled(TransformationWhiteWrapper)`
  background-color: ${colors.white};
  color: ${colors.black};
  ${Text} {
    &:first-child {
      padding-top: 0;
    }
  }
`;

const CustomTransformationBlockDiv = styled(TransformationBlockDiv)`
  @media (min-width: 875px) {
    justify-content: flex-start;
    margin-left: 50px;
    margin-top: 230px;
  }
`;

const CustomTransformationBlock = styled(TransformationBlock)`
  display: flex;
  flex-direction: column;
  color: ${colors.black};

  @media (min-width: 875px) {
    flex-direction: row;
  }

  @media (max-width: 874px) {
    margin-bottom: 40px;
  }
`;

const MotivesToInvest = ({ content }) => {
  return (
    <CustomWrapper id="motivesToInvest">
      <CustomTransformationBlock>
        <TransformationBlockDiv>
          <span className="background-number">3</span>
          <TitleTextWrapper width="100%">
            <LineAboveText />
            <SubHeadTitle>{content.title}</SubHeadTitle>
          </TitleTextWrapper>

          <MarkdownPreview source={content.content1} />
          <QuoteText>{content.highlight1}</QuoteText>
          <MarkdownPreview source={content.content2} />
        </TransformationBlockDiv>
        <CustomTransformationBlockDiv>
          {content.content3 && (
            <MarkdownPreview
              source={content.content3}
              style={{ marginTop: 26 }}
            />
          )}

          <QuoteText>{content.highlight2}</QuoteText>

          <MarkdownPreview source={content.content4} />
        </CustomTransformationBlockDiv>
      </CustomTransformationBlock>
    </CustomWrapper>
  );
};

export default MotivesToInvest;
