import React, { useState } from 'react';
import { graphql } from 'gatsby';
import ContentLanding from 'templates/RichContent/ContentLanding';
import HeadingLanding from 'templates/RichContent/HeaderLanding';
import SEO from 'components/SEO';

import Footer from 'components/Footer';

import TransformationHeader from './TransformationHeader';
import UncertainFuture from './UncertainFuture';
import Summary from './Summary';
import WhyTransform from './WhyTransform';
import StartToday from './StartToday';
import MotivesToInvest from './MotivesToInvest';
import YouShouldInvest from './YouShouldInvest';
import Activators from './Activators';
import HowToPrepare from './HowToPrepare';
import IsItWorthIt from './IsItWorthIt';
import WeAreAteliware from './WeAreAteliware';
import ShowCase from './ShowCase';
import SurvivingMatter from './SurvivingMatter';
import ReinventYourBusiness from './ReinventYourBusiness';

const DigitalTransformation = ({
  data: {
    ateliware: { digitalTransformationPages, footerMenu }
  },
  pageContext
}) => {
  pageContext = JSON.parse(
    '{"type": "digitalTransformation", "baseURL": "/"}'
  );
  //  debugger;
  const [pageData] = useState(digitalTransformationPages[0]);
  const { seo } = pageData;

  const showCases = getComponentList(pageData, {
    title: /section12Case.*Title/,
    content: /section12Case.*Content/,
    image: /section12Case.*Image/
  }).filter((showCase) => !!showCase.title);

  function getComponentList(obj, patterns) {
    const keys = Object.keys(patterns);
    const data = {};
    keys.forEach((key) => {
      const pattern = patterns[key];
      const patternKeys = filterKeys(obj, pattern);
      data[key] = patternKeys.map((patternKey) => {
        return obj[patternKey];
      });
    });
    const itemsLength = data[keys[0]].length;
    return Array(itemsLength)
      .fill(null)
      .map((info, index) => {
        return keys.reduce((acc, key) => {
          return { ...acc, [key]: data[key][index] };
        }, {});
      });
  }

  function filterKeys(obj, pattern) {
    const keys = Object.keys(obj);
    return keys.filter((key) => pattern.exec(key));
  }

  return (
    <React.Fragment>
      <SEO
        meta_title={seo?.metaTitle}
        meta_description={seo?.metaDescription}
        og_title={seo?.ogTitle}
        og_description={seo?.ogDescription}
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={pageContext.locale}
      />

      {/* Content 1 */}
      <HeadingLanding />

      <ContentLanding>
        {/* Bloco 1 */}
        <TransformationHeader
          content={{
            title: pageData.section1Title,
            subtitle: pageData.section1Subtitle
          }}
        />
        {/* Bloco 2 */}
        <UncertainFuture
          content={{
            title: pageData.section2Title,
            content1: pageData.section2Content1,
            content2: pageData.section2Content2,
            slider: pageData.section2Slider,
            highlight: pageData.section2HighlightDark
          }}
        />
      </ContentLanding>

      {/* Summary */}
      <ContentLanding>
        {/* Bloco 3 */}
        <Summary
          content={{
            title: pageData.section3Title,
            subtitle: pageData.section3Subtitle,
            backgroundImage: pageData.section3BackgroundImage
          }}
        />
      </ContentLanding>

      {/* Content 2 */}
      <ContentLanding>
        {/* Bloco 4 */}
        <WhyTransform
          content={{
            title: pageData.section4Title,
            content1: pageData.section4Content1,
            content2: pageData.section4Content2,
            highlight: pageData.section4HighlightDark,
            image: pageData.section4Image
          }}
        />

        {/* Bloco 5 */}
        <StartToday
          content={{
            title: pageData.section5Title,
            content1: pageData.section5Content1,
            content2: pageData.section5Content2,
            highlightMedium: pageData.section5HighlightMedium,
            highlight: pageData.section5HighlightRed
          }}
        />

        {/* Bloco 6 */}
        <MotivesToInvest
          content={{
            title: pageData.section6Title,
            content1: pageData.section6Content1,
            content2: pageData.section6Content2,
            content3: pageData.section6Content3,
            content4: pageData.section6Content4,
            highlight1: pageData.section6HighlightRed,
            highlight2: pageData.section6HighlightRed2
          }}
        />

        {/* Bloco 7 */}
        <YouShouldInvest
          content={{
            title: pageData.section6HighlightDark,
            reasonsList: pageData.section6HighlightWhite,
            content: pageData.section6Content5,
            highlight1: pageData.section6HighlightRed3,
            backgroundImage: pageData.section6BackgroundImage
          }}
        />

        {/* Bloco 8 */}
        <Activators
          content={{
            title: pageData.section7Title,
            content1: pageData.section7Content1,
            content2: pageData.section7Content2,
            highlight: pageData.section7HighlightRed,
            list: pageData.section7List
          }}
        />

        {/* Bloco 9 */}
        <HowToPrepare
          content={{
            title: pageData.section8Title,
            content1: pageData.section8Content1,
            content2: pageData.section8Content2,
            content3: pageData.section8Content3,
            highlight: pageData.section8HighlightRed,
            list: pageData.section8List
          }}
        />

        {/* Bloco 10 */}
        <IsItWorthIt
          content={{
            title: pageData.section9Title,
            content1: pageData.section9Content1,
            content2: pageData.section9Content2,
            highlight: pageData.section9Highlight,
            highlightRed: pageData.section9HighlightRed
          }}
        />

        {/* Bloco 11 */}
        <WeAreAteliware
          content={{
            title: pageData.section10Title,
            partnerTitle: pageData.section10TitlePartner,
            content1: pageData.section10Content1,
            highlight1: pageData.section10Highlight1,
            content2: pageData.section10Content2,
            image: pageData.section10Image,
            highlight2: pageData.section10Highlight2,
            content3: pageData.section10Content3,
            content4: pageData.section10Content4
          }}
          labItems={getComponentList(pageData, {
            logo: /section10Lab.*Logo/,
            content: /section10Lab.*Content/
          }).filter((item) => !!item.content)}
        />

        {/* Bloco 13 */}
        <ShowCase
          content={{ title: pageData.section12Title }}
          showCases={showCases}
        />

        {/* Bloco 14 */}
        <SurvivingMatter
          content={{
            title: pageData.section13Title,
            content1: pageData.section13Content1,
            highlight1: pageData.section13Highlight1,
            content2: pageData.section13Content2,
            highlight2: pageData.section13Highlight2,
            content3: pageData.section13Content3,
            content4: pageData.section13Content4
          }}
        />

        {/* Bloco 15 */}
        <ReinventYourBusiness
          content={{
            title: pageData.section14Title,
            subtitle: pageData.section14Subtitle,
            cta: pageData.section14Cta,
            actionUrl: pageData.section14ActionUrl
          }}
        />

        {/* Footer */}
        <Footer
          footerMenu={footerMenu}
          pageContext={pageContext}
          privatePolicyTitle="Politica de Privacidade"
        />
      </ContentLanding>
    </React.Fragment>
  );
};

export const pageQuery = graphql`
  query LandingPageContent {
    ateliware {
      digitalTransformationPages {
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
          }
        }
        section1Title
        section1Subtitle
        section2Title
        section2Slider
        section2Content1
        section2HighlightDark
        section2Content2
        section3Title
        section3Subtitle
        section3BackgroundImage {
          url
        }
        section4Title
        section4Content1
        section4HighlightDark
        section4Content2
        section4Image {
          url
        }
        section5Title
        section5Content1
        section5Content2
        section5HighlightMedium
        section5HighlightRed
        section6Title
        section6Content1
        section6Content2
        section6Content3
        section6HighlightRed
        section6HighlightRed2
        section6HighlightDark
        section6HighlightWhite
        section6HighlightRed3
        section6BackgroundImage {
          url
        }
        section6Content4
        section6Content5
        section7Title
        section7Content1
        section7List
        section7Content2
        section7HighlightRed
        section8Content1
        section8Content2
        section8Content3
        section8HighlightRed
        section8List
        section8Title
        section9Title
        section9Content1
        section9Highlight
        section9Content2
        section9HighlightRed
        section10Title
        section10TitlePartner
        section10Content1
        section10Highlight1
        section10Content2
        section10Image {
          url
        }
        section10Highlight2
        section10Lab1Logo {
          url
        }
        section10Lab1Content
        section10Lab2Logo {
          url
        }
        section10Lab2Content
        section10Lab3Logo {
          url
        }
        section10Lab3Content
        section10Content3
        section10Content4
        section12Title
        section12Case1Title
        section12Case1Content
        section12Case1Image {
          url
        }
        section12Case2Title
        section12Case2Content
        section12Case2Image {
          url
        }
        section12Case3Title
        section12Case3Content
        section12Case3Image {
          url
        }
        section12Case4Title
        section12Case4Content
        section12Case4Image {
          url
        }
        section13Title
        section13Content1
        section13Highlight1
        section13Content2
        section13Highlight2
        section13Content3
        section13Content4
        section14Title
        section14Subtitle
        section14Cta
        section14ActionUrl
      }
      footerMenu: menu(where: { title: "footer" }, locales: pt) {
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
    }
  }
`;

export default DigitalTransformation;
