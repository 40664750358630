import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

import GreyConnectedDotsIcon from 'assets/images/richcontent/grey-connected-dots.svg';
import RedConnectedDotsIcon from 'assets/images/richcontent/red-connected-dots.svg';
import DarkConnectedDotsIcon from 'assets/images/richcontent/dark-connected-dots.svg';

export const TransformationWrapper = styled.div`
  width: 100%;
  min-height: 487px;
  color: ${colors.white};
  position: relative;
  background-color: ${colors.gray300};
  font-weight: 300;

  /* ${({ isBottomDiagonalRight }) =>
    isBottomDiagonalRight &&
    `
      -webkit-clip-path: polygon(0 0, 100% 0%, 100% 83%, 0 100%);
      clip-path: polygon(0 0, 100% 0%, 100% 83%, 0 100%);

      @media (max-width: 875px) {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 89%, 0 100%);
        clip-path: polygon(0 0, 100% 0%, 100% 89%, 0 100%);
      }
  `}

  ${({ isBottomDiagonalLeft }) =>
    isBottomDiagonalLeft &&
    `
      -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 93%);
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 93%);
  `}

  ${({ isTopDiagonalLeft }) =>
    isTopDiagonalLeft &&
    `
      -webkit-clip-path: polygon(0 11%, 100% 0%, 100% 100%, 0 100%);
      clip-path: polygon(0 11%, 100% 0%, 100% 100%, 0 100%);
  `} */

  a {
    color: ${colors.blue400};
  }
`;

export const TransformationWhiteWrapper = styled.div`
  background-color: ${colors.white};
  width: 100%;
  position: relative;

  h2 {
    color: ${colors.gray300};
  }

  a {
    color: ${colors.blue400};
  }

  @media (max-width: 874px) {
    padding: 0 20px;
  }
`;

export const TransformationBlock = styled.div`
  margin: 0 auto;
  max-width: 1215px;
  z-index: 200;
  padding: 35px 19px;
  position: relative;
  text-align: ${({ alignContentCenter }) =>
    alignContentCenter ? 'center' : 'left'};
  font-weight: 300;

  @media (min-width: 875px) {
    padding: 50px 10px 80px;
  }

  @media (max-width: 874px) {
    padding: 0;
    padding-top: 1px;
    margin-bottom: 80px;
  }
`;

export const TransformationBlockDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  img.block-img {
    display: none;

    @media (min-width: 875px) {
      display: block;
      width: 588px;
      height: 483px;
    }
  }

  span.background-number {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: -80px;
    margin-left: -15px;
    font-family: ${fonts.primary};
    font-weight: bold;
    font-size: 150px;
    line-height: 225px;
    color: rgba(0, 0, 0, 0.1);

    @media (min-width: 875px) {
      font-size: 280px;
      line-height: 525px;
      margin-top: -200px;
      margin-left: -60px;
    }
  }

  @media (min-width: 875px) {
    width: ${(props) => (props.width ? props.width : '50%')};
  }
`;

export const TextWrapper = styled(TransformationBlock)`
  @media (max-width: 1200px) {
    column-count: 1;
    column-gap: 0;
  }
`;

export const QuoteBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  @media (min-width: 875px) {
    margin-top: 0;
    margin-left: 30%;
  }
`;

export const QuoteText = styled.h3`
  font-family: ${fonts.secondary};
  font-size: 26px;
  font-style: normal;
  color: ${colors.red400};
  text-align: left;
  font-weight: bold;
  line-height: 35px;

  @media (max-width: 875px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const BigQuoteText = styled.h3`
  font-family: ${fonts.primary};
  font-size: 18px;
  color: ${colors.red400};
  text-align: left;
  margin: 0;
  font-weight: bold;

  @media (min-width: 875px) {
    font-size: 36px;
  }
`;

export const TitleTextWrapper = styled.div`
  position: relative;

  @media (min-width: 875px) {
    width: ${(props) => (props.width ? props.width : '35%')};
  }
`;

export const LineAboveText = styled.div`
  display: ${(props) => (props.isSubtitle ? 'none' : 'block')};
  position: relative;
  top: 14px;
  left: 0;
  content: '';
  background: ${colors.gray300};
  width: 92px;
  height: 2px;
  position: absolute;
  margin-bottom: 20px;

  @media (min-width: 875px) {
    display: ${(props) => props.isSubtitle && 'block'};
  }
`;

export const HeadTitle = styled.h1`
  font-family: ${fonts.primary};
  font-size: 65px;
  color: ${colors.gray300};
  position: relative;
  text-align: left;
  margin: 0;

  @media (max-width: 875px) {
    font-size: 38px;
    line-height: 57px;
  }
`;

export const AltTitle = styled.h4`
  color: ${colors.white};
  font-family: ${fonts.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  position: relative;
  text-align: left;
  color: white;

  @media (max-width: 875px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const SubHeadTitle = styled.h2`
  display: inline-block;
  font-family: ${fonts.primary};
  font-weight: bold;
  font-size: 24px;
  color: ${colors.gray300};
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: left;
  position: relative;
  text-transform: lowercase;

  @media (min-width: 875px) {
    font-size: 48px;
  }

  @media (max-width: 874px) {
    margin-bottom: 20px;
  }
`;

export const BodyTitle = styled.h4`
  color: ${colors.black};
  font-family: ${fonts.secondary};
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  position: relative;
  text-align: left;

  @media (max-width: 875px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const Text = styled.p`
  font-family: ${fonts.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  padding: 7px 0;
`;

export const ItemTitle = styled.p`
  font-family: ${fonts.secondary};
  font-size: 16px;
  font-weight: bold;
`;

export const GreyConnectedDots = styled.div`
  width: 470px;
  height: 1683px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(${GreyConnectedDotsIcon}) no-repeat;
  z-index: 1;
`;

export const RedConnectedDots = styled.div`
  width: 470px;
  min-height: 1683px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(${RedConnectedDotsIcon}) no-repeat;
  z-index: 1;
`;

export const DarkConnectedDots = styled.div`
  width: 470px;
  min-height: 1683px;
  position: absolute;
  top: 0;
  right: 0;
  background: url(${DarkConnectedDotsIcon}) no-repeat;
  z-index: 1;
`;

export const DesktopListDivider = styled.div`
  border: 1px solid ${colors.red300};
  margin-left: 26px;
  margin-right: 46px;
`;
