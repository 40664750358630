import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from 'components/Logo';

import { StyledHeaderLanding, LandingButton } from './styled';

const HeadButton = styled(LandingButton)`
  border-radius: 0;
`;
const baseURL = 'https://ateliware.com';

const HeadingLanding = () => {
  return (
    <StyledHeaderLanding>
      <Link target="_blank" to={baseURL} title="ateliware">
        <Logo />
      </Link>

      <HeadButton
        target="_blank"
        href="/contact"
        title="Vamos Conversar?"
        callToAction={true}
        variant="light-outline"
      >
        Vamos conversar?
      </HeadButton>
    </StyledHeaderLanding>
  );
};

export default HeadingLanding;
